<template>
  <div class="loading">
    {{ process }}%
  </div>
</template>

<script>
export default {
  props: {
    process: Number
  }
}
</script>

<style>
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  background: #d4d9d1;

  font-size: 40px;
  align-items: center;
  justify-content: center;
}
</style>