export default {
  images: [
    "http://ali-img.kaiyanapp.com/9a999c8f1c6dace370ad78dc35a93850.gif",
    "http://ali-img.kaiyanapp.com/3aa7b179c5dec35e395dd4f2e0b269ef.gif",
    "http://ali-img.kaiyanapp.com/b22446c6b7738f2234144af432458f60.gif",
    "http://ali-img.kaiyanapp.com/15097715cc160cbcbd3e8f3d43bc1419.gif",
    "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
  ],
  expands: {
    e2: [
      "http://ali-img.kaiyanapp.com/e4134a2a2921f676a1422c4d9b85baa9.gif",
      "http://ali-img.kaiyanapp.com/3e95a9db229b2f49c7b157eb533daeb2.gif",
      "http://ali-img.kaiyanapp.com/3743edd819837e8a781cbd57555fa300.gif",
      "http://ali-img.kaiyanapp.com/fb6268a9c83a2a8728eeea677d4e4ca4.gif",
      "http://ali-img.kaiyanapp.com/d1868ad7e0b0e046250dc61b636fe682.gif",
      "http://ali-img.kaiyanapp.com/0fdba07f83617c7c1ccdf967fa7f5d8d.gif",
      "http://ali-img.kaiyanapp.com/cfb46c09a5a883345734f81c00e9cb1b.gif",
      "http://ali-img.kaiyanapp.com/f5081a0003c9cb7ee4ee2b34587f9ebf.gif",
    ],
    e3: [
      "http://ali-img.kaiyanapp.com/9ced9eb943fd3297e5f46e5ad90d9287.gif",
      "http://ali-img.kaiyanapp.com/f3fc3e3dc704628784f78806a08bb93f.gif",
      "http://ali-img.kaiyanapp.com/8a464a956410a27f464b1fd909703595.gif",
      "http://ali-img.kaiyanapp.com/077148e46dbb40248c7c980ef8c1cd2b.gif",
      "http://ali-img.kaiyanapp.com/1e54a5a10d251d4790f165a562fe4d3b.gif",
    ],
    e4: [
      "http://ali-img.kaiyanapp.com/47fb814c72092054a7f00649f47bd59b.gif",
      "http://ali-img.kaiyanapp.com/3472f5759d493c8c76ad24fc12f65e9e.gif",
      "http://ali-img.kaiyanapp.com/022d0531c7d0a4ca1700fc8b217ae297.gif",
      "http://ali-img.kaiyanapp.com/b99ba7f2f2e717f9a195c2932a10048e.gif",
      "http://ali-img.kaiyanapp.com/6600a72ad264c3726b65a8f0148a7eba.gif",
    ],
    e5: [
      "http://ali-img.kaiyanapp.com/4514678e89637bd47270559b22fe9028.gif",
      "http://ali-img.kaiyanapp.com/a198f27c9a5d796033beed5e9f844451.gif",
      "http://ali-img.kaiyanapp.com/1998b8e4dc3a0e23a4a97daa3ee1a0e0.gif",
      "http://ali-img.kaiyanapp.com/8054c1e1e3b526750763fb901592d3af.gif",
      "http://ali-img.kaiyanapp.com/df1ac99d79a94743e00a3ddda96d848c.gif",
      "http://ali-img.kaiyanapp.com/c02545577fa2471b8a90fd03f56a23f8.gif",
    ]
  }
}