<template>
  <div class="expand-image">
    <img class="image" :src="image" @click="handleExpands" />
    <transition enter-active-class="animate__animated animate__fadeIn">
      <div class="image-panel" v-show="isShowPanel">
        <img class="image" :src="item" v-for="(item, index) of expands[panelImages]" :key="`p_${panelImages}_${index}`">
      </div>
    </transition>
  </div>
</template>

<script>
import source from "../source";
export default {
  props: {
    image: String,
    panelImages: String
  },
  data() {
    return {
      expands: Object.freeze(source.expands),
      isShowPanel: false
    }
  },
  methods: {
    handleExpands() {
      this.isShowPanel = true;
    }
  }
}
</script>

<style scoped>
.expand-image {
}

.expand-image .image {
  width: 100%;
}

</style>

<style>
.expands-enter-active, .expands-leave-active {
  transition: height 1s;
}
.expands-enter, .expands-leave-to {
  height: 0;
}
</style>