class Load {
  data = []
  callback = () => {}
  loadedCount = 0
  total = 0

  constructor(data = [], cb = () => {}) {
    this.data = data
    this.callback = cb
  }

  on() {
    this.total = this.data.length;
    let preloadImages = [];
    for (let i = 0; i < this.total; i++) {
      preloadImages[i] = new Image()

      preloadImages[i].onload = preloadImages[i].onerror = () => {
        this.count()
      }
      preloadImages[i].src = this.data[i]
    }
  }

  count() {
    this.loadedCount++
    this.callback(this.loadedCount)
  }
}

export default Load